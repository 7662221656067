<template>
    <el-date-picker
        v-model="data.value"
        :type="data.dateType || 'daterange'"
        :popper-class="data.customClassName"
        range-separator="至"
        :start-placeholder="data.startPlaceholder || '开始日期'"
        :end-placeholder="data.endPlaceholder || '结束日期'"
        :picker-options="data.pickerOptions"
        :default-time="data.defaultTime"
        :clearable="data.clearable"
        prefix-icon=0
        :format="data.format || 'yyyy-MM-dd'"
        :value-format="data.valueFormat || 'yyyy-MM-dd'"
        @change="handleChange"
        @clear="handleChange"
        @focus="handleFocus"
    >
    </el-date-picker>
</template>

<script>
    export default {
        name: "DatePicker",
        props: {
            data: {
                type: Object
            }
        },
        methods: {
            handleChange(e) {
                this.$emit('datePickerChange', this.data.value);
                this.$eventDispatch('datePickerBlur', this.data.value)
            },
            handleFocus(e) {
                this.$eventDispatch('datePickerFocus', this.data.value)
            },
            cleardate(){
                this.data.value=[]
            }
        }
    }
</script>

<style scoped>
</style>
