<template>
    <div class="Cascader">
        <el-col :span="data.tip ? 23 : 20">
            <el-cascader
                ref="cascaderRef"
                v-model="data.value"
                :props="data.cascaderProps"
                :options="data.list"
                :collapse-tags="typeof data.collapseTags === 'undefined' ? true : data.collapseTags"
                :placeholder="(data && data.placeholder) ? data.placeholder : '请选择'"
                :clearable="typeof data.clearable !== 'undefined' ? data.clearable : true"
                filterable
                @change="changeSel(data)"
                :class="{
                    collapseCascader: typeof data.collapseTags === 'undefined',
                    'el-cascader_single': data.value && data.value.length == 1
                }"
                popper-class="expand-filter-cascader-popover"
                :append-to-body="false"
            ></el-cascader>
        </el-col>

        <el-col  class="tip" v-if="data.tip" :span="1">
            <el-tooltip effect="dark" :content="data.tip" placement="top">
                <i class="el-icon-question"></i>
            </el-tooltip>
        </el-col>
    </div>
</template>

<script>
    export default {
        name: 'Select',
        props: {
            data: {
                type: Object
            }
        },
        methods: {
            changeSel (data) {
                this.$emit('changeCascader', {
                    data: data,
                    ref: this.$refs.cascaderRef
                })
                this.$eventDispatch('changeCascader', {
                    data: data,
                    ref: this.$refs.cascaderRef
                })
                // this.$eventBus.$emit('changeSel',data)
            }
        }
    }
</script>

<style lang="scss" scoped>
::v-deep .collapseCascader {
    width: 100%;

    .el-input__inner {
        height: 32px !important;
    }

    .el-cascader__tags {
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        /* margin-right: 50px; */

        .el-tag {
            max-width: calc(100% - 50px);

            + .el-cascader__search-input {
                margin-left: 6px;
                min-width: 7px;
            }
        }
    }
}

::v-deep .el-cascader_single {
    .el-cascader__tags {
        .el-tag {
            max-width: 100%;
            width: 100%;
        }

        .el-cascader__search-input {
            display: none;
        }
    }
}
</style>

<style>
.expand-filter-cascader-popover .el-cascader-panel {max-height: 210px; }
.expand-filter-cascader-popover .el-cascader-panel .el-cascader-menu {
    min-height: 50px;
}
.expand-filter-cascader-popover .el-scrollbar__wrap { overflow-x: hidden; }
.expand-filter-cascader-popover .el-cascader-node:not(.is-disabled):focus, .el-cascader-node:not(.is-disabled):hover {
    background-color: #f0f0f0 !important;
}
</style>
