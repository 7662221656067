<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-05-13 17:12:07
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-05-27 17:27:36
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\Sub\FormData\NoRDate.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
        <el-col :span="20">
            <el-date-picker v-model="data.value" type="date" :placeholder="data.placeholder" prefix-icon=0
                :picker-options="data.picker" format="yyyy-MM-dd" :value-format="data.valueFormat || 'yyyy-MM-dd'" :clearable="data.clearable">
            </el-date-picker>
        </el-col>
        <template v-if="data.fn">
            <div v-for="(item, index) of data.fn" :key="index">
                <el-col :span="4">
                    <CheckboxSub v-if="item.type == 'checkbox'" :data="item" :parent="data" />
                </el-col>
            </div>
        </template>
    </div>
</template>
<script>
    import CheckboxSub from './Sub/Checkbox.vue';
    export default {
        name: 'date',
        components: {
            CheckboxSub
        },
        props: {
            data: {
                type: Object
            }
        },
    }
</script>

<style lang="scss" scoped>
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 100% !important;
    }
</style>
