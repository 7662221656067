<template>
    <div>
        <el-col :span="data.fn ? 20 : 24">
            <el-input v-model="data.value" clearable :disabled="data.disabled" :maxlength="data.maxlength" :show-word-limit="data.showLimit" :show-password="data.showPassword" :placeholder="data.placeholder" @input="inputInput(data)" @blur="inputBule(data)" @change="inputChange(data)" :style="{width: data.width}">
                <template v-if="data.appendText" slot="append">{{ data.appendText }}</template>
            </el-input>
            <template v-if="data.fn">
                <div
                    v-for="(item, index) of data.fn"
                    :key="index"
                >
                    <TextSub
                        v-if="item.type == 'text'"
                        :data="item"
                        :parent="data"
                    />
                </div>
            </template>
        </el-col>
        <template v-if="data.fn">
            <div
                v-for="(item, index) of data.fn"
                :key="index"
            >
                <el-col :span="4">
                    <ButtonSub
                        v-if="item.type == 'button'"
                        :data="item"
                        :parent="data"
                    />
                </el-col>
            </div>
        </template>
    </div>
</template>

<script>
import ButtonSub from "./Sub/Button.vue";
import TextSub from "./Sub/Text.vue";

export default {
    name: "Input",
    components: {
        ButtonSub,
        TextSub,
    },
    props: {
        data: {
            type: Object,
        },
    },
    methods: {
        inputInput(data) {
            this.$emit("inputInput", data);
        },
        inputBule(data) {
            this.$eventBus.$emit("inputBule", data);
        },
        inputChange(data) {
            this.$eventBus.$emit("inputChange", data);
        }
    },
    watch: {
        "data.value": {
            handler(newVal, oldVal) {
                this.data.value = newVal;
            },
            deep: true,
        },
    },
};
</script>

<style scoped>

</style>
