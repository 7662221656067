<template>
    <div>
        <el-col :span="data.fn ? 20 : 24">
            <el-input v-model="data.value" clearable :disabled="data.disabled" :maxlength="data.maxlength" :show-word-limit="data.showLimit" :show-password="data.showPassword" :placeholder="data.placeholder" :style="{width: data.width}" @clear="handelClear(data)" @focus="handlerFocus(data)">
                <template v-if="data.appendText" slot="append">{{ data.appendText }}</template>
            </el-input>
            <template v-if="data.fn">
                <div
                    v-for="(item, index) of data.fn"
                    :key="index"
                >
                    <TextSub
                        v-if="item.type == 'text'"
                        :data="item"
                        :parent="data"
                    />
                </div>
            </template>
        </el-col>
        <template v-if="data.fn">
            <div
                v-for="(item, index) of data.fn"
                :key="index"
            >
                <el-col :span="4">
                    <ButtonSub
                        v-if="item.type == 'button'"
                        :data="item"
                        :parent="data"
                    />
                </el-col>
            </div>
        </template>
    </div>
</template>

<script>
import ButtonSub from "./Sub/Button.vue";
import TextSub from "./Sub/Text.vue";

export default {
    name: "Organization",
    components: {
        ButtonSub,
        TextSub,
    },
    props: {
        data: {
            type: Object,
        },
    },
    methods: {
        handlerFocus(data) {
            this.$eventDispatch("handlerFocus", data);
        },
        handelClear(data) {
            console.log(data,'走了吗');
            this.$eventDispatch("handelClear", data);
        }
    },
    watch: {
        "data.value": {
            handler(newVal, oldVal) {
                this.data.value = newVal;
            },
            deep: true,
        },
    },
};
</script>

<style>
input:not(.el-cascader) .el-input__inner {
    height: 36px !important;
    line-height: 36px !important;
}
</style>
